<style>
textarea {
	font-family: monospace;
	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;
}
</style>

<template>
	<b-container>
		<b-card v-show="standalone" header="额外选项" class="mt-3">
			<b-checkbox v-model="addDWC">获得最新的稳定版DuetWebControl</b-checkbox>
			<b-checkbox v-model="addRRF">获取最新的稳定版RepRapFirmware</b-checkbox>
		</b-card>
		
		<b-card header="其他" class="mt-3">
			<b-checkbox v-model="panelDue" class="mb-3">启用对PanelDue的支持</b-checkbox>
			<label for="custom_settings">自定义config.g设置：</label>
			<label class="float-right"><a href="https://docs.duet3d.com/en/User_manual/Reference/Gcodes" target="_blank">所有可用G代码的完整列表</a></label>
			<b-form-textarea id="custom_settings" v-model="customSettings" rows="4" max-rows="8" @keydown.tab.exact.prevent="onTextareaTab"></b-form-textarea>
		</b-card>
	</b-container>
</template>

<script>
'use strict'

import { mapState, mapGetters, mapMutations } from 'vuex'
import { mapFields, mapMultiRowFields } from 'vuex-map-fields'

export default {
	computed: mapFields({
		addDWC: 'addDWC',
		addRRF: 'addRRF',
		panelDue: 'template.panelDue',
		customSettings: 'template.custom_settings',
		standalone: 'template.standalone'
	}),
	methods: {
		onTextareaTab(e) {
			const originalSelectionStart = e.target.selectionStart;
			const textStart = e.target.value.slice(0, originalSelectionStart), textEnd = e.target.value.slice(originalSelectionStart);
			this.custom_settings = `${textStart}\t${textEnd}`;
			e.target.value = this.custom_settings;
			e.target.selectionEnd = e.target.selectionStart = originalSelectionStart + 1;
		}
	}
}
</script>


<template>
	<b-container>
		<b-card header="一般设置">
			<b-form-row>
				<b-col>
					<b-form-group label="主板：">
						<b-form-select :value="template.board" @change="setSelectedBoard($event)" v-preset="preset.board" title="运行固件的主板">
							<option value="duet06">Duet 0.6</option>
							<option value="duet085">Duet 0.8.5</option>
							<option value="duetwifi10">Duet 2 WiFi</option>
							<option value="duetethernet10">Duet 2 Ethernet</option>
							<option value="duetm10">Duet 2 Maestro</option>
							<option value="duet3">Duet 3 MB 6HC</option>
							<option value="duet3_mini5plus_ethernet">Duet 3 Mini 5+ (Ethernet)</option>
							<option value="duet3_mini5plus_wifi">Duet 3 Mini 5+ (WiFi)</option>
						</b-form-select>
					</b-form-group>
				</b-col>

				<b-col>
					<b-form-group label="固件版本：">
						<b-form-select :value="template.firmware" @change="setFirmware($event)" :disabled="template.board.startsWith('duet0')" v-preset="preset.firmware" title="主板上运行的固件版本">
							<option :value="1.16" disabled>1.16 或 older (不再支持)</option>
							<option :value="1.17" disabled>1.17 至 1.19 (不再支持)</option>
							<option :value="1.2" disabled>1.20 (不再支持)</option>
							<option :value="1.21" :disabled="template.board.startsWith('duet3')">1.21</option>
							<option :value="2" :disabled="template.board.startsWith('duet3')">2.0</option>
							<option :value="2.03" :disabled="template.board.startsWith('duet3')">2.03 至 2.05</option>
							<option :value="3">3.0 至 3.2</option>
							<option :value="3.3">3.3 或更高版本（稳定版）</option>
						</b-form-select>
					</b-form-group>
				</b-col>

				<b-col v-if="template.standalone">
					<b-form-group label="打印机名称：">
						<b-form-input v-model.trim="name" v-preset="preset.network.name" title="打印机名称（M550）。 如果您使用mDNS，则可以通过Myprinter.local访问您的打印机" maxlength="40" type="text" required></b-form-input>
					</b-form-group>
				</b-col>
			</b-form-row>

			<b-form-checkbox v-if="template.board.startsWith('duet3')" v-model="standalone" v-preset.left title="在不连接单板计算机的情况下以独立模式运行RepRapFirmware">在没有SBC的情况下以独立模式运行</b-form-checkbox>
			<b-form-checkbox v-model="nvram" v-preset.left title="在启动时加载已保存的配置参数（M501）">在启动过程结束时读取config-override.g文件（提供与Marlin中的EEPROM选项类似的功能）</b-form-checkbox>
			<b-form-checkbox v-if="board.hasPowerFailureDetection" v-model="autoSaveEnabled" v-preset.left="preset.auto_save.enabled" title="发生电源故障时，将最后的有效打印参数存储在SD卡上(M911)">电源故障时保存打印状态</b-form-checkbox>
			<div v-show="autoSaveEnabled" class="mt-3 pl-4">
				<b-form-row>
					<b-col>
						<b-form-group label="自动保存阈值：">
							<b-input-group append="V">
								<b-form-input v-model.number="autoSaveThreshold" type="number" step="any" required></b-form-input>
							</b-input-group>
						</b-form-group>
					</b-col>

					<b-col>
						<b-form-group label="恢复阈值：">
							<b-input-group append="V">
								<b-form-input v-model.number="autoSaveResumeThreshold" type="number" step="any" required></b-form-input>
							</b-input-group>
						</b-form-group>
					</b-col>

					<b-col cols="6">
						<b-form-group label="执行自动保存时运行的G代码：">
							<b-form-input v-model.trim="autoSaveGCodes" type="text" required></b-form-input>
						</b-form-group>
					</b-col>
				</b-form-row>

				<span>重要提示：必须手动设置文件/sys/resurrect-prologue.g才能恢复工作(详见 <a href="https://docs.duet3d.com/en/User_manual/Tuning/Resume#setting-up-the-sysresurrect-prologueg-file" target="_blank">Duet3D wiki</a>)</span>
			</div>
		</b-card>

		<b-card no-body header="机器结构" class="mt-3">
			<b-tabs card pills v-model="geometry">
				<b-tab title="笛卡尔" :title-link-class="{ 'font-weight-bold' : preset.geometry.type === 'cartesian' }">
					<geometry-form></geometry-form>
					<span>该结构使用单独的电机驱动每个轴</span>
				</b-tab>

				<b-tab title="CoreXY" :title-link-class="{ 'font-weight-bold' : preset.geometry.type === 'corexy' }">
					<geometry-form></geometry-form>
					<span>该结构使用两个电机同时控制XY的移动</span>
				</b-tab>

				<b-tab title="CoreXZ" :title-link-class="{ 'font-weight-bold' : preset.geometry.type === 'corexz' }">
					<geometry-form></geometry-form>
					<span>该结构两个电机同时控制XZ的移动</span>
				</b-tab>

				<b-tab title="三角洲" :title-link-class="{ 'font-weight-bold' : preset.geometry.type === 'delta' }">
					<b-form-row>
						<b-col>
							<b-form-group label="三角洲半径：">
								<b-input-group append="mm">
									<b-form-input v-model.number="deltaRadius" v-preset="preset.geometry.delta_radius" title="当效应器位于中心时，每个杆对向的水平距离，在关节中心之间测量" :min="printRadius" type="number" step="any" required></b-form-input>
								</b-input-group>
							</b-form-group>
							<b-form-group label="可打印半径：">
								<b-input-group append="mm">
									<b-form-input v-model.number="printRadius" v-preset="preset.geometry.print_radius" title="安全打印半径" min="1" :max="deltaRadius" type="number" step="any" required></b-form-input>
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col>
							<b-form-group label="最小Z：">
								<b-input-group append="mm">
									<b-form-input v-model.number="zMin" v-preset="preset.geometry.z_min" title="最小Z高度" :max="homedHeight" type="number" step="any" required></b-form-input>
								</b-input-group>
							</b-form-group>
							<b-form-group label="并联臂长度：">
								<b-input-group append="mm">
									<b-form-input v-model.number="rodLength" v-preset="preset.geometry.rod_length" title="塔的中心与效应器的关节之间的距离" min="1" type="number" step="any" required></b-form-input>
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col>
							<b-form-group label="归零高度：">
								<b-input-group append="mm">
									<b-form-input v-model.number="homedHeight" v-preset="preset.geometry.homed_height" title="最高Z高度" :min="homedHeight" min="1" :max="maxCarriageTravel" type="number" step="any" required></b-form-input>
								</b-input-group>
							</b-form-group>
							<b-form-group label="最大行程：">
								<b-input-group append="mm">
									<b-form-input v-model.number="maxCarriageTravel" v-preset="preset.geometry.max_carriage_travel" title="Z方向最大行程，滑车从限位移动到另一端长度" type="number" required></b-form-input>
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-form-row>

					<span>该结构由并联臂定位喷头</span>
				</b-tab>
			</b-tabs>
		</b-card>

		<b-card header="归零首选项" class="mt-3">
			<b-form-row>
				<b-col>
					<b-form-group label="归零速度 (第一遍)：">
						<b-input-group append="mm/s">
							<b-form-input v-model.number="homingSpeedFast" v-preset="preset.homing_speed_fast" title="归零分两个阶段进行。 第一遍是首次归零的速度" type="number" step="any" required></b-form-input>
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group label="归零速度 (第二遍)：">
						<b-input-group append="mm/s">
							<b-form-input v-model.number="homingSpeedSlow" v-preset="preset.homing_speed_slow" title="第二遍是指第一次触发限位开关后回退一定距离再一次归零的速度" type="number" step="any" required></b-form-input>
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group label="归零速度：">
						<b-input-group append="mm/s">
							<b-form-input v-model.number="travelSpeed" v-preset="preset.travel_speed" title="其他归零动作的默认移动速度" type="number" step="any" required></b-form-input>
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col align-self="center">
					<b-form-group label="Z 提升：">
						<b-input-group append="mm">
							<b-form-input v-model.number="zDiveHeight" v-preset="preset.z_dive_height" title="用于调平探针和笛卡尔归零文件的Z提升量" type="number" step="any" required></b-form-input>
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-form-row>

			<b-checkbox v-show="template.geometry.type === 'delta'" v-model="slowHoming" v-preset.left="preset.slow_homing" title="降低归零速度。 建议用于初始校准" class="mb-2">将初始归位速度设置为10％进行校准，并为homedelta.g添加注释</b-checkbox>
			<b-checkbox v-model="lowDiveHeight" v-preset.left="preset.geometry.low_dive_height" title="在config.g中生成额外的M558代码，您可以在初次校准调平后删除它">初次调平时将喷嘴距离热床高度设置为30mm </b-checkbox>
		</b-card>

		<b-modal ref="modalDuet06" title="检查您的主板" size="lg" cancel-title="No" cancel-variant="danger" ok-title="Yes" ok-variant="success" @ok="setBoardSeriesResistor(4700)" @cancel="setBoardSeriesResistor(1000)">
			<h4>您的Duet 0.6主板上是否标有“ 4.7K”字样，或者它是与RepRapPro Ormerod 2一起出厂的？</h4>
			<br>
			<span class="text-muted">注：以前的Duet 0.6主板使用1个kΩ的串联电阻</span>
		</b-modal>
	</b-container>
</template>

<script>
'use strict';

import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { mapFields, mapMultiRowFields } from 'vuex-map-fields'

import GeneralGeometryForm from '../components/GeneralGeometryForm.vue'

const geometryTypes = ['cartesian', 'corexy', 'corexz', 'delta'];

export default {
	components: {
		'geometry-form' : GeneralGeometryForm
	},
	computed: {
		...mapState(['board', 'preset', 'template']),
		...mapFields({
			name: 'template.network.name',
			standalone: 'template.standalone',
			nvram: 'template.nvram',
			autoSaveEnabled: 'template.auto_save.enabled',
			autoSaveThreshold: 'template.auto_save.save_threshold',
			autoSaveResumeThreshold: 'template.auto_save.resume_threshold',
			autoSaveGCodes: 'template.auto_save.gcodes_to_run',
			deltaRadius: 'template.geometry.delta_radius',
			zMin: 'template.geometry.z_min',
			homedHeight: 'template.geometry.homed_height',
			rodLength: 'template.geometry.rod_length',
			maxCarriageTravel: 'template.geometry.max_carriage_travel',
			homingSpeedFast: 'template.homing_speed_fast',
			homingSpeedSlow: 'template.homing_speed_slow',
			travelSpeed: 'template.travel_speed',
			zDiveHeight: 'template.z_dive_height',
			slowHoming: 'template.slow_homing',
			lowDiveHeight: 'template.geometry.low_dive_height'
		}),
		geometry: {
			get() {
				const geometryType = this.template.geometry.type;
				return geometryTypes.findIndex(item => item === geometryType);
			},
			set(value) { this.setGeometry(geometryTypes[value]); }
		},
		printRadius: {
			get() { return this.template.geometry.print_radius; },
			set(value) { this.setPrintRadius(value); }
		}
	},
	methods: {
		...mapActions(['setBoard']),
		...mapMutations(['setFirmware', 'setBoardSeriesResistor', 'setGeometry', 'setPrintRadius']),
		setSelectedBoard(value) {
			this.setBoard(value);
			if (value === 'duet06') {
				// Determine Duet 0.6 series resistor
				this.$refs.modalDuet06.show();
			}
		}
	}
}
</script>

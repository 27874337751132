<style scoped>
.grid-compensation fieldset {
	margin-bottom: 0;
}
</style>

<template>
	<b-container>
		<b-alert :show="template.geometry.type !== 'delta'">
			<strong>注意：</strong> 不推荐使用3/4/5点热床调平。它已被新的网格补偿取代。
		</b-alert>

		<b-card v-show="template.geometry.type === 'delta'" no-body header="三角洲自动校准">
			<div class="card-body">
				<b-form-row>
					<b-col>
						<b-form-group label="外围点数：">
							<b-select v-model.number="peripheralPoints" @change="updateProbePoints" v-preset="preset.peripheral_points" title="外部探测点数">
								<option value="3">3</option>
								<option value="6">6</option>
								<option value="10">10</option>
								<option value="12">12</option>
							</b-select>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group label="中间点数：">
							<b-select v-model.number="halfwayPoints" @change="updateProbePoints" v-preset="preset.halfway_points" title="内部探测点数量">
								<option value="0">0</option>
								<option value="3">3</option>
								<option value="6" :disabled="template.peripheral_points + 6 > 16">6</option>
							</b-select>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group label="校准因子系数：">
							<b-select v-model.number="calibrationFactors" v-preset="preset.calibration_factors" title="用于自动校准的调整因子数">
								<option value="4">4</option>
								<option value="6">6</option>
								<option value="7">7</option>
								<option value="8">8</option>
								<option value="9">9</option>
							</b-select>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group label="探测半径：">
							<b-input-group append="mm">
								<b-form-input v-model.number="probeRadius" @change="updateProbePoints" v-preset="preset.probe_radius" title="外部探测的半径" :max="template.geometry.delta_radius" type="number" step="any" required></b-form-input>
							</b-input-group>
						</b-form-group>
					</b-col>
				</b-form-row>

				<b-checkbox v-model="homeFirst" v-preset.left="preset.home_first" title="在探测之前，把喷头移动热床上">自动校准之喷头移动到热床上</b-checkbox>
			</div>

			<points-table v-model="probePoints" :minX="-template.probe_radius" :minY="-template.probe_radius" :maxX="template.probe_radius" :maxY="template.probe_radius" :disableLastZ="true"></points-table>
		</b-card>

		<b-card header="热床网格补偿" class="mt-3">
			<b-form-row class="grid-compensation">
				<template v-if="template.geometry.type !== 'delta'">
					<b-col>
						<b-form-group label="X最小：">
							<b-input-group append="mm">
								<b-form-input v-model.number="xMin" v-preset="preset.mesh.x_min" title="探针的最小X坐标" :min="template.geometry.mins[0]" :max="template.geometry.maxes[0]" type="number" step="any" required></b-form-input>
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group label="X最大：">
							<b-input-group append="mm">
								<b-form-input v-model.number="xMax" v-preset="preset.mesh.x_max" title="探针的最大X坐标" :min="template.geometry.mins[0]" :max="template.geometry.maxes[0]" type="number" step="any" required></b-form-input>

							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group label="Y最小：">
							<b-input-group append="mm">
								<b-form-input v-model.number="yMin" v-preset="preset.mesh.y_min" title="探针的最小Y坐标" :min="template.geometry.mins[1]" :max="template.geometry.maxes[1]" type="number" step="any" required></b-form-input>

							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group label="Y最大：">
							<b-input-group append="mm">
								<b-form-input v-model.number="yMax" v-preset="preset.mesh.y_max" title="探针的最大Y坐标" :min="template.geometry.mins[1]" :max="template.geometry.maxes[1]" type="number" step="any" required></b-form-input>

							</b-input-group>
						</b-form-group>
					</b-col>
				</template>
				<b-col v-else>
					<b-form-group label="探测半径：">
						<b-input-group append="mm">
							<b-form-input v-model.number="radius" v-preset="preset.mesh.radius" title="探测点半径" :min="1" :max="template.geometry.delta_radius" type="number" step="any" required></b-form-input>

						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group label="网格间距：">
						<b-input-group append="mm">
							<b-form-input v-model.number="spacing" v-preset="preset.mesh.spacing" title="探针点间距" :min="1" :max="maxGridSpacing" type="number" step="any" required></b-form-input>

						</b-input-group>
					</b-form-group>
				</b-col>
			</b-form-row>
		</b-card>

		<b-card header="轴倾斜补偿" class="mt-3">
			<b-form-checkbox v-model="orthogonalCompensation" v-preset.left="preset.orthogonal.compensation" title="选中此项可通过M556启用轴倾斜补偿">启用轴倾斜补偿</b-form-checkbox>
			<b-form-row v-show="orthogonalCompensation" class="mt-3 pl-4">
				<b-col>
					<b-form-group label="测量高度：" class="mb-0">
						<b-input-group append="mm">
							<b-form-input v-model.number="orthogonalHeight" v-preset="preset.orthogonal.height" title="轴倾斜补偿测量的偏差高度" min="1" type="number" step="any" required></b-form-input>
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group label="X偏差：" class="mb-0">
						<b-input-group append="mm">
							<b-form-input v-model.number="xDeviation" v-preset="preset.orthogonal.deviations[0]" title="XY方向的偏差" type="number" step="any" required></b-form-input>
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group label="Y偏差：" class="mb-0">
						<b-input-group append="mm">
							<b-form-input v-model.number="yDeviation" v-preset="preset.orthogonal.deviations[1]" title="YZ方向偏差" type="number" step="any" required></b-form-input>
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group label="Z偏差：" class="mb-0">
						<b-input-group append="mm">
							<b-form-input v-model.number="zDeviation" v-preset="preset.orthogonal.deviations[2]" title="XZ方向偏差" type="number" step="any" required></b-form-input>
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-form-row>
		</b-card>
	</b-container>
</template>

<script>
'use strict';

import { mapState, mapMutations } from 'vuex'
import { mapFields, mapMultiRowFields } from 'vuex-map-fields'

import PointsTable from '../components/CompensationPointsTable.vue'

export default {
	components: {
		'points-table': PointsTable
	},
	computed: {
		...mapState(['preset', 'template']),
		...mapFields({
			peripheralPoints: 'template.peripheral_points',
			halfwayPoints: 'template.halfway_points',
			calibrationFactors: 'template.calibration_factors',
			probeRadius: 'template.probe_radius',
			homeFirst: 'template.home_first',
			xMin: 'template.mesh.x_min',
			xMax: 'template.mesh.x_max',
			yMin: 'template.mesh.y_min',
			yMax: 'template.mesh.y_max',
			radius: 'template.mesh.radius',
			spacing: 'template.mesh.spacing',
			orthogonalCompensation: 'template.orthogonal.compensation',
			orthogonalHeight: 'template.orthogonal.height'
		}),
		...mapMultiRowFields({
			probePoints: 'template.probe.points'
		}),
		maxGridSpacing() { return (this.template.geometry === 'delta') ? (this.template.mesh.radius / 2) : (this.template.bed.width / 2); },
		xDeviation: {
			get() { return this.template.orthogonal.deviations[0]; },
			set(value) { this.setOrthogonalDeviation({ axis: 0, value }); }
		},
		yDeviation: {
			get() { return this.template.orthogonal.deviations[1]; },
			set(value) { this.setOrthogonalDeviation({ axis: 1, value }); }
		},
		zDeviation: {
			get() { return this.template.orthogonal.deviations[2]; },
			set(value) { this.setOrthogonalDeviation({ axis: 2, value }); }
		}
	},
	methods: mapMutations(['updateProbePoints', 'setOrthogonalDeviation'])
}
</script>

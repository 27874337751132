<template>
	<b-container>
		<b-card v-if="template.standalone" header="网络设置">
			<b-form-checkbox v-model="networkEnabled" v-preset.left="preset.network.enabled" title="勾选此项可启用网络功能（M552 S1）">启用网络</b-form-checkbox>
			<div v-show="networkEnabled" class="pl-4">
				<b-form-row class="mt-3">
					<b-col>
						<b-form-group label="web界面(HTTP)、FTP和Telnet的密码:">
							<b-form-input v-model.trim="password" v-b-tooltip.hover title="可选密码，用于保护打印机免受本地网络中其他人的侵害（M551）" maxlength="64" placeholder="reprap" type="text"></b-form-input>
						</b-form-group>
					</b-col>

					<template v-if="board.hasWiFi && template.firmware >= 3">
						<b-col cols="auto">
							<b-form-group label="WiFi接入点名称：">
								<b-form-input v-model.trim="ssid" v-b-tooltip.hover title="要连接的WiFi的名称。 或者，您可以通过M587手动连接" maxlength="32" placeholder="我的无线网" type="text"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col cols="auto">
							<b-form-group label="WiFi 密码：">
								<b-form-input v-model.trim="ssidPassword" v-b-tooltip.hover title="您的WiFi的密码" maxlength="64" placeholder="无" type="text"></b-form-input>
							</b-form-group>
						</b-col>
					</template>

					<b-col v-if="board.hasEthernet">
						<b-form-group label="MAC地址：">
							<b-form-input v-model.trim="macAddress" v-preset="preset.network.mac_address" title="你机器的MAC地址。这通常是自动生成的" :formatter="formatMAC" :state="isValidMAC(template.network.mac_address)" maxlength="17" placeholder="自动产生" type="text"></b-form-input>
						</b-form-group>
					</b-col>
				</b-form-row>

				<b-checkbox v-model="dhcp" v-preset.left="preset.network.dhcp" title="使用DHCP从路由器获取动态IP地址（M552）">通过DHCP获取动态IP地址</b-checkbox>
				<b-form-row v-show="!dhcp" class="pl-4 mt-3">
					<b-col>
						<b-form-group label="IP地址：">
							<b-form-input v-model.trim="ipAddress" v-preset="preset.network.ip" title="打印机的静态IP地址（M552）。这个地址在本地网络上是唯一的" :state="isValidIP(this.template.network.ip)" maxlength="15" type="text" required></b-form-input>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group label="子网掩码：">
							<b-form-input v-model.trim="netmask" v-preset="preset.network.netmask" title="本地网络的子网掩码（M553）" :state="isValidNetmask(this.template.network.netmask)" maxlength="15" type="text" required></b-form-input>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group label="网关：">
							<b-form-input v-model.trim="gateway" v-preset="preset.network.ip" title="这里是路由器的IP地址（M554）" :state="isValidIP(this.template.network.gateway)" maxlength="15" type="text" required></b-form-input>
						</b-form-group>
					</b-col>
				</b-form-row>

				<b-checkbox v-model="http" v-preset.left="preset.network.protocols.http" title="启用超文本传输协议以提供对Web界面的访问" class="mt-3">启用HTTP（用于Web界面）</b-checkbox>
				<b-checkbox v-model="ftp" v-preset.left="preset.network.protocols.ftp" title="启用文件传输协议。 请注意，RepRapFirmware仅支持一个并发连接！">开启 FTP</b-checkbox>
				<b-checkbox v-model="telnet" v-preset.left="preset.network.protocols.telnet" title="启用Telnet。 请注意，RepRapFirmware仅支持一个并发连接！">启用Telnet</b-checkbox>
			</div>
		</b-card>
	</b-container>
</template>

<script>
'use strict';

import { mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
	computed: {
		...mapState(['board', 'preset', 'template']),
		...mapFields({
			networkEnabled: 'template.network.enabled',
			password: 'template.network.password',
			ssid: 'template.network.ssid',
			ssidPassword: 'template.network.ssid_password',
			macAddress: 'template.network.mac_address',
			dhcp: 'template.network.dhcp',
			ipAddress: 'template.network.ip',
			netmask: 'template.network.netmask',
			gateway: 'template.network.gateway',
			http: 'template.network.protocols.http',
			ftp: 'template.network.protocols.ftp',
			telnet: 'template.network.protocols.telnet'
		})
	},
	methods: {
		formatMAC(value, event) {
			return value.toUpperCase()
		},
		isValidIP(ipAddress) {
			let matches = /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/.exec(ipAddress);
			if (!matches) {
				return false;
			}
			return (matches[1] < 255) && (matches[2] < 255) && (matches[3] < 255) && (matches[4] < 255);
		},
		isValidNetmask(netmask) {
			let matches = /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/.exec(netmask);
			if (!matches) {
				return false;
			}
			return (matches[1] < 256) && (matches[2] < 256) && (matches[3] < 256) && (matches[4] < 256);
		},
		isValidMAC(macAddress) {
			return (macAddress === '') || (/^[\dA-F][\dA-F]:[\dA-F][\dA-F]:[\dA-F][\dA-F]:[\dA-F][\dA-F]:[\dA-F][\dA-F]:[\dA-F][\dA-F]$/.test(macAddress));
		}
	}
}
</script>

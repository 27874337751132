<style scoped>
label.btn {
	align-items: center;
	justify-content: center;
	display: flex;
}

.table td {
	vertical-align: middle;
}
</style>

<template>
	<b-container>
		<b-card no-body>
			<template slot="header">
				<span class="mt-2">限位开关设置</span>
				<abbr id="endstop_note" class="float-right" title="当到触碰开关时，轴位置将设置为相应的最小/最大。如果限位未安装在轴末端，则必须在归位移动（g1..s1）到归零文件后添加额外的g92代码，以便设置新的轴位置。当使用Z探针将Z轴归零时，固件会将新的Z位置设置为指定的触发高度">注释</abbr>
			</template>

			<table class="table mb-0">
				<thead>
					<th>&nbsp;&nbsp;轴</th>
					<th>限位类型</th>
					<th>限位位置</th>
				</thead>
				<tbody>
					<tr v-for="i in [0, 1, 2]">
						<td class="pl-4 pt-3">
							{{ ['X', 'Y', 'Z'][i] }}
						</td>
						<td>
							<b-form-radio-group buttons button-variant="outline-primary" :checked="template.drives[i].endstop_type" @change="updateDrive({ drive: i, et: $event })" v-preset.left="preset.drives[i].endstop_type" title="该轴的限位开关类型" :name="'endstopType' + i" class="w-100">
								<b-form-radio :value="0" class="w-100" v-b-tooltip.bottom  title="通过G92手动归位">无</b-form-radio>
								<b-form-radio :value="1" :disabled="template.firmware >= 3 && !template.drives[i].endstop_pin" class="w-100" v-b-tooltip.bottom title="限位开关触发时将信号从GND拉至+3.3V（常闭开关）">
									{{ template.firmware >= 3 ? '限位开关' : '常&nbsp;闭&nbsp;(NC)' }}
								</b-form-radio>
								<b-form-radio v-show="template.firmware < 3" :value="2" class="w-100" v-b-tooltip.bottom title="限位开关触发时将信号从GND拉至+3.3V（常开开关）">常&nbsp;开&nbsp;(NO)</b-form-radio>
								<b-form-radio :value="3" class="w-100" :disabled="template.probe.type === 'noprobe'" v-b-tooltip.bottom title="使用 Z-Probe ">Z探针</b-form-radio>
								<b-form-radio :value="4" class="w-100" :disabled="!board.hasMotorLoadDetection" v-b-tooltip.bottom title="步进电机失速检测">无传感器</b-form-radio>
							</b-form-radio-group>
						</td>
						<td>
							<b-form-radio-group buttons button-variant="outline-primary" :checked="template.drives[i].endstop_location" v-preset.right="preset.drives[i].endstop_location" @change="updateDrive({ drive: i, el: $event })" title="限位开关在各个轴的最大最小位置" :name="'endstopLocation' + i" class="w-100">
								<b-form-radio :value="1" class="w-100" :disabled="template.geometry.type == 'delta'">最小</b-form-radio>
								<b-form-radio :value="2" class="w-100">最大</b-form-radio>
							</b-form-radio-group>
						</td>
					</tr>
				</tbody>
			</table>
		</b-card>

		<b-alert :show="template.drives.findIndex(drive => drive.endstop_type === 4) !== -1">
			<strong>注释：</strong> 通过检测电机丢步进行无传感器归零需要额外的配置。详细信息，请参阅 <a href="https://docs.duet3d.com/en/User_manual/Connecting_hardware/Sensors_stall_detection" target="_blank">Duet3D Wiki</a> 更多信息。
		</b-alert>

		<b-card class="mt-3" header="Z轴探针">
			<b-form-row>
				<b-col>
					<b-form-group label="探针X偏移：">
						<b-input-group append="mm">
							<b-form-input v-model.number="probeXOffset" v-preset="preset.probe.x_offset" title="X方向上的Z探针偏移" :disabled="template.probe.type == 'noprobe'" type="number" step="any" required></b-form-input>
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group label="探针Y偏移：">
						<b-input-group append="mm">
							<b-form-input v-model.number="probeYOffset" v-preset="preset.probe.y_offset" title="Y方向上的Z探针偏移" :disabled="template.probe.type == 'noprobe'" type="number" step="any" required></b-form-input>
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group label="调平速度：">
						<b-input-group append="mm/s">
							<b-form-input v-model.number="probeSpeed" v-preset="preset.probe.speed" title="探测热床点的速度" type="number" step="any" required></b-form-input>
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col align-self="center">
					<b-form-checkbox v-model="probeDeploy" v-preset="preset.probe.deploy" title="通过运行宏文件伸出或缩回机械探测器" :disabled="template.probe.type == 'bltouch'">伸出/缩回探针</b-form-checkbox>
				</b-col>
			</b-form-row>

			<b-card no-body header="探针类型">
				<b-tabs card pills v-model="probeType">
					<b-tab title="没有Z探针" :title-link-class="{ 'font-weight-bold' : preset.probe.type === 'noprobe' }" value="noprobe">
						没有安装Z探针。 必须手动确定喷嘴和床之间的距离。
					</b-tab>
					<b-tab title="开关" :disabled="template.firmware >= 3 && !template.probe.input_pin" :title-link-class="{ 'font-weight-bold' : preset.probe.type === 'switch' }" value="switch">
						<z-probe-values></z-probe-values>
						开关用于确定喷嘴和床之间的距离。
						<template v-if="template.firmware >= 3">
							<br>
							如果不使用高电平开关，则必须在<router-link to="Mapping">I/O映射</router-link>页面上反转Z-probe端口。
						</template>

						<template v-if="template.geometry.type === 'delta'">
							<br><br>
							<span>This switch must be connected to the E0 endstop terminal!</span>
						</template>
						<template v-else-if="template.firmware < 3" >
							<br><br>
							<span>此开关必须连接到Z探针接口和<strong>而不是</strong>Z限位开关接口！</span>
						</template>
					</b-tab>
					<b-tab title="未调制或智能IR探针" :title-link-class="{ 'font-weight-bold' : preset.probe.type === 'unmodulated' }" value="unmodulated">
						<z-probe-values></z-probe-values>
						使用未调制的Z探针来确定喷嘴和热床之间的距离（没有触发信号）
					</b-tab>
					<b-tab title="简单的调制IR探针" :disabled="template.firmware >= 3 && (!template.probe.input_pin || !template.probe.modulation_pin)" :title-link-class="{ 'font-weight-bold' : preset.probe.type === 'modulated' }" value="modulated">
						<z-probe-values></z-probe-values>
						调制的Z探针用于确定喷嘴和热床之间的距离（带触发信号）
					</b-tab>
					<b-tab title="智能效应器或压电" :disabled="template.firmware >= 3 && (!template.probe.input_pin || !template.probe.modulation_pin)" :title-link-class="{ 'font-weight-bold' : preset.probe.type === 'effector' }" value="effector">
						<z-probe-values>
							<b-col>
								<b-form-group label="恢复时间：">
									<b-input-group append="s">
										<b-form-input v-model.number="template.probe.recovery_time" v-preset title="智能效应器/压电器的恢复时间" type="number" step="any" required></b-form-input>
									</b-input-group>
								</b-form-group>
							</b-col>
						</z-probe-values>

						适用于Delta打印机的官方<a href="https://www.duet3d.com/DeltaSmartEffector" target="_blank">Duet3D 智能效应器</a> (使用方法<a href="https://docs.duet3d.com/en/Duet3D_hardware/Accessories/Smart_Effector" target="_blank">Duet3D Wiki</a>)
					</b-tab>
					<b-tab title="BLTouch" :disabled="template.firmware >= 3 && (!template.probe.input_pin || !template.probe.pwm_pin)" :title-link-class="{ 'font-weight-bold' : preset.probe.type === 'bltouch' }" value="bltouch">
						<z-probe-values :hide-value="true">
							<b-col v-if="template.firmware < 3">
								<b-form-group label="舵机控制通道：">
									<b-select v-model="pwmChannel" v-b-tooltip.hover title="用于控制BLTouch探针的PWM通道">
										<optgroup v-if="pwmChannels.constructor === Object" v-for="(group, name) in pwmChannels" :label="name" :key="name">
											<option v-for="(item, index) in group" :value="item.value" v-text="item.text" :key="index"></option>
										</optgroup>
										<option v-if="pwmChannels.constructor === Array" v-for="(item, index) in pwmChannels" :value="item.value" v-text="item.text" :key="index"></option>
									</b-select>
								</b-form-group>
							</b-col>
						</z-probe-values>
						<span>使用BLTouch为Z-探测(另见 <a href="https://duet3d.dozuki.com/Wiki/Connecting_a_Z_probe#Section_BLTouch" target="_blank">Duet3D wiki</a>)</span>
						<br><br>
						<span>在连接之前，请确保将其更改为+3.3V而不是+5V，否则可能会损坏板！</span>
					</b-tab>
				</b-tabs>

				<b-alert :show="template.firmware >= 3 && (!template.probe.input_pin || !template.probe.pwm_pin)" class="mb-0">
					<strong>注意：</strong>如果要配置BLTouch探针，必须在<router-link to="/Mapping">I / O映射</router-link>页面上指定输入和PWM控制引脚。
				</b-alert>
			</b-card>
		</b-card>
	</b-container>
</template>

<script>
'use strict';

import { mapState, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import ZProbeValues from '../components/EndstopsZProbeValues.vue'
import Template from '../store/Template.js'

const probeTypes = ['noprobe', 'switch', 'unmodulated', 'modulated', 'effector', 'bltouch'];

export default {
	components: {
		'z-probe-values': ZProbeValues
	},
	computed: {
		...mapState(['board', 'preset', 'template']),
		...mapFields({
			probeXOffset: 'template.probe.x_offset',
			probeYOffset: 'template.probe.y_offset',
			probeSpeed: 'template.probe.speed',
			probeDeploy: 'template.probe.deploy',
			probePwmChannel: 'template.probe.pwm_channel',
			probePwmInverted: 'template.probe.pwm_inverted'
		}),
		pwmChannel: {
			get() {
				return (this.probePwmInverted ? '!' : '') + this.probePwmChannel;
			},
			set(value) {
				console.log(value);

				let inverted = false;
				if (value.startsWith('!')) {
					inverted = true;
					value = value.substring(1);
				}
				this.probePwmChannel = value;
				this.probePwmInverted = inverted;
			}
		},
		pwmChannels() {
			const heaterChannels = [], pwmChannels = [];
			if (this.template.board.startsWith('duetm')) {
				heaterChannels.push({
					text: 'Z Probe MOD',
					value: '64'
				});
			} else {
				for(let i = 0; i < 8; i++) {
					const disabled = (this.template.heaters.length > i) && (this.template.heaters[i] !== null);
					heaterChannels.push({
						text: (i == 0) ? 'Bed Channel' : `E${i - 1} Channel`,
						value: '!' + i,
						disabled
					});

					if (this.template.board.startsWith('duetethernet') || this.template.board.startsWith('duetwifi')) {
						if (i > 2) {
							pwmChannels.push({
								text: `PWM${i - 2}`,
								value: i.toString(),
								disabled
							});
						}
					}
				}
			}

			if (pwmChannels.length > 0) {
				return {
					'加热器通道': heaterChannels,
					'DueX扩展板上的PWM通道': pwmChannels
				};
			}
			return heaterChannels;
		},
		/*pwmChannelPreset() {
			if (this.template.board.startsWith('duetm')) {
				return '64';
			}
			return (this.preset.probe.pwm_inverted ? '!' : '') + this.preset.probe.pwm_channel;
		},*/
		probeType: {
			get() {
				const probeType = this.template.probe.type;
				return probeTypes.findIndex(item => item == probeType);
			},
			set(value) { this.setProbeType(probeTypes[value]); }
		}
	},
	methods: mapMutations(['updateDrive', 'setProbeType'])
}
</script>

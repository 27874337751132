<template>
	<b-container>
		<div class="text-center">
			<h2 class="mb-4">欢迎使用RepRapFirmware配置工具</h2>
			<h4 class="text-muted mb-5">请按照此向导获取打印机的配置文件</h4>
		</div>

		<b-card bg-variant="light" class="mb-3">
			<p>如果您使用的自带RepRapFirmware的打印机，可以在这选择模板：</p>

			<b-form-group>
				<b-form-radio-group v-model="selectedMachine" name="machine" stacked class="mb-2">
					<b-form-radio value="minikossel">T3P3 Mini Kossel</b-form-radio>
					<b-form-radio value="ormerod1">RepRapPro Ormerod 1</b-form-radio>
					<b-form-radio value="ormerod2">RepRapPro Ormerod 2</b-form-radio>
					<b-form-radio value="fisher">RepRapPro Fisher</b-form-radio>
					<b-form-radio value="ender3pro">Creality Ender 3 Pro</b-form-radio>
				</b-form-radio-group>

				<p>以下机器模板由用户提供，尚未经过全面测试：</p>

				<b-form-radio-group v-model="selectedMachine" name="machine" stacked class="mb-3">
					<b-form-radio value="anet-a8">Anet A8</b-form-radio>
					<b-form-radio value="distech-prometheus-system">Distech Prometheus System</b-form-radio>
					<b-form-radio value="reach3d">Reach3D Printer</b-form-radio>
					<b-form-radio value="wanhao-di3">Wanhao Duplicator i3</b-form-radio>
					<b-form-radio value="dwmaker-z2">Dwmaker Z2 </b-form-radio>
					<b-form-radio value="dwmaker-z6">Dwmaker Z6 </b-form-radio>
				</b-form-radio-group>

				<p>或者，您可以通过创建新的配置或加载已有的.json文件来更改自己的配置：</p>

				<b-form-radio-group v-model="selectedMachine" name="machine" stacked>
					<b-form-radio value="custom">自定义配置</b-form-radio>
					<b-form-radio value="existing">使用现有json文件</b-form-radio>
				</b-form-radio-group>
			</b-form-group>
		</b-card>

		<div class="text-center mt-4">
			<span class="text-muted">中文版由<a href="https://brst.pro" target="_blank">BRST</a>汉化.
			<a href="https://rrf.blramaker.com/Duet/Multi.htm" target="_blank">Duet集群控制</a>网站（只适用3.0之前的固件）</span>
				<br>
			<span class="text-danger">注意：中文版遇到BUG请联系<a href="https://bbs.blramaker.com" target="_blank">BRST</a></span>
			
			<span class="text-muted">
				<!--注意：如果您遇到问题，请报告您的问题<a href="https://github.com/chrishamm/configtool" target="_blank">GitHub</a>.-->
				<br>
				某些配置选项尚不可用。在这种情况下，请参阅<a href="https://docs.duet3d.com" target="_blank">Duet3D wiki</a>进行手动增加配置功能.
				<br>
				此Web应用程序是完全开源的，并根据条款获得许可 <a href="https://www.gnu.org/licenses/gpl-3.0.html" target="_blank">GPLv3</a>. 版本 {{ version }}</span>
		</div>

		<input ref="inputJsonFile" type="file" accept="application/json" hidden @change="fileSelected"></input>
	</b-container>
</template>

<script>
'use strict';

import { mapState, mapMutations } from 'vuex'

import { version } from '../../package.json'

import Template from '../store/Template.js'

export default {
	created() {
		this.machineSelection = this.machine;
	},
	data() {
		return {
			machineSelection: 'custom',
			version
		}
	},
	computed: {
		...mapState(['machine']),
		selectedMachine: {
			get() { return this.machineSelection; },
			set(value) {
				if (this.machineSelection == value) {
					return;
				}
				this.machineSelection = value;

				if (value === 'custom') {
					// Apply custom template
					this.setTemplate({ name: 'custom' });
				} else if (value === 'existing') {
					// Load the machine template from the user's device
					this.$refs.inputJsonFile.click();
					this.$nextTick(function() {
						this.machineSelection = this.machine;
					});
				} else {
					// Load the machine template from the server
					const xhr = new XMLHttpRequest(), that = this;
					xhr.open('GET', `machines/${value}.json`, true);
					xhr.responseType = 'json';
					xhr.onload = function() {
						if (xhr.status >= 200 && xhr.status < 300) {
							that.setTemplate({ name: value, data: xhr.response });
						} else {
							this.setTemplate({ name: 'custom' });
							alert(`Failed to load template from server:\n\n${xhr.status} ${xhr.statusText}`);
						}
					}
					xhr.onerror = function() {
						this.setTemplate({ name: 'custom' });
						alert('Failed to load template from server:\n\nNetwork error');
					};
					xhr.send(null);
				}
			}
		}
	},
	methods: {
		...mapMutations(['setTemplate']),
		fileSelected(e) {
			if (e.target.files.length > 0) {
				const fileReader = new FileReader();
				const that = this;
				fileReader.onload = function(e) {
					try {
						that.setTemplate({ name: 'existing', data: JSON.parse(e.target.result) });
					} catch (e) {
						console.log(e);
						alert('Error: The specified file could not be read!\n\n' + e);
					}
				};
				fileReader.readAsText(e.target.files[0]);
			}
		},
	},
	watch: {
		machine(to) {
			this.machineSelection = to;
		}
	}
}
</script>
